<template>
  <div class="reserve">
    <TopBar pageTitle="报名" />
    <div class="info">
      <el-form ref="form" :model="formData" :rules="rules">
       <el-form-item label="手机号" prop="phone">
          <el-input type="number"  v-model="formData.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formData.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" class="textarea" v-model="formData.remark" placeholder="请填写备注，选填"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn-box">
      <button class="btn sub" @click="subBtn('form')">提交报名信息</button>
    </div>
  </div>
</template>
<script>
import TopBar from "@/m/common/topBar.vue";
import { mapMutations} from "vuex";
export default {
  name: "m-reserve",
  data() {
    return {
      id: '',          //ID
      reserveType: 0,  //预约类型
      formData: {
         activity_id:'',
        source:'ksk主站',
        name: "",
        phone: "",
        remark: "",
      },
      urlHead:'http',
      rules: {
        phone: [
          { required: true, message: '请填写您的联系电话', trigger: 'blur' },
          { min: 11, max: 11, message: '请填写正确的电话号码', trigger: 'blur' },
        ],
      }
    };
  },
  components: {
    TopBar
  },
  mounted() {
    let urlHttp = window.location.href
    this.urlHead = 'http'
    if(urlHttp.includes('https')){
       this.urlHead = 'https'
    }
    this.id = this.$route.query.id;
  },
  methods: {
    ...mapMutations(['ShowTipModal']), //tip弹窗
     //提交
    subBtn(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.activity_id = this.id;
          this.$axios.post(this.urlHead+`://newkskapi.kskstudy.com/om/v1/api/activity/activityForm`, this.formData).then(res => {
            if(res.code == 1){
              this.ShowTipModal({
                text: res.msg,     //提示弹窗内容
                ico: 'success',
                fun: ()=>{
                  this.$router.back(-1);
                }
              })
            }else{
              this.ShowTipModal({
                text: res.msg,     //提示弹窗内容
              })
            }
          })
        }else{
          this.ShowTipModal({
            text: '有未填写的内容',     //提示弹窗内容
          })
        }
      })
    }
  },
};
</script>
<style scoped lang="scss">
.reserve{
  // padding-bottom: 140px;
  background: #F9F9F9;
  min-height: 100vh;
}
.info{
  padding: 40px 36px;
  .tip{
    padding: 14px 28px;
    background-color: #F9F9F9;
    border-radius: 3px;
    font-size: 22px;
    color: #999;
    line-height: 15px;
  }
}
.btn-box{
  width: 100%;
  height: 140px;
  // padding: 0 36px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  .btn{
    width: 80%;
    margin: 0 auto;
    height: 80px;
    line-height: 80px;
    border-radius: 10px;
    font-size: 28px;
    &.reset{
      color: #0065DF;
      background-color: #E5F0FC;
    }
    &.sub{
      color: #fff;
      background-color: #0065DF;
    }
  }
}
::v-deep .el-form{
  padding-top: 20px;
  .el-form-item{
    margin-bottom: 26px;
    &:last-child{
      margin-bottom: 0;
    }
    .first{
      margin-bottom: 20px !important;
    }
    .el-form-item__label{
      display: block;
      width: 100% !important;
      margin: 0;
      text-align: left;
      font-size: 28px;
      color: #000;
      font-weight: 500;
      float: none;
      margin-bottom: 10px;
    }
    .el-form-item__content,
    .el-date-editor{
      width: 100% !important;
      margin: 0 !important;
    }
  }
  .el-input__inner,
  .el-input__icon{
    height: 100px;
    line-height: 100px;
    font-size: 30px;
  }
  .textarea,
  textarea{
    height: 200px;
    resize: none;
  }
}

</style>